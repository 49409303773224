import React, { useState } from 'react';
import { Link } from 'gatsby';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // State to toggle menu visibility

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="flex flex-col sm:flex-row justify-between items-center py-4 bg-[#f5f1ee]">
      <div className="flex justify-between w-full px-4 sm:px-9">
        <div className="brand flex items-center">
          <Link to="/" className="text-lg font-semibold">Marcian CX</Link>
        </div>
        <p 
          className="sm:hidden text-3xl focus:outline-none"
          style={{ lineHeight: '0.1' }} 
          onClick={toggleMenu}
        >
          &#9776;
        </p>
        <nav className="hidden sm:flex space-x-4">
          <a href="https://podcast.marcian.cx" target="_blank" rel="noopener noreferrer" className="text-lg">
            Podcast
          </a>
          <Link to="/essays" className="text-lg">Essays</Link>
          <Link to="/poetry" className="text-lg">Poetry</Link>
          <Link to="/e-books" className="text-lg">E-Books</Link>
          {/* <Link to="/about" className="text-lg font-light">About</Link> */}
        </nav>
      </div>
      {/* Mobile Nav - Visible when 'isOpen' is true */}
      <nav className={`flex flex-col space-y-2 mt-4 sm:hidden ${isOpen ? 'block' : 'hidden'}`}>
        <a href="https://podcast.marcian.cx" target="_blank" rel="noopener noreferrer" className="text-lg">
            Podcast
        </a>
        <Link to="/essays" className="text-lg">Essays</Link>
        <Link to="/poetry" className="text-lg">Poetry</Link>
        <Link to="/e-books" className="text-lg">E-Books</Link>
        {/* <Link to="/about" className="text-lg font-light">About</Link> */}
      </nav>
    </header>
  );
};

export default Header;
