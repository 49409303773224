// src/components/Layout.js

import React from 'react';
import Header from './header';
import './styles.css';

const Layout = ({ children }) => {  
  return (
    <main>
      <Header />
      {children}
      <footer
      style={{
        marginTop: `var(--space-5)`,
        fontSize: `var(--font-sm)`,
      }}>
      © {new Date().getFullYear()} &middot; E. H. Marcian Sakarya
    </footer>
    </main>
  );
};

export default Layout;
